import React from 'react';
import PropTypes from 'prop-types';

import { StoryblokComponent } from 'gatsby-source-storyblok';

const DynamicGridBreaker = ({ blok: { row, content }, queryParamString }) => {
  return (
    <StoryblokComponent
      blok={{ ...(content[0] || {}), queryParamString }}
      row={row}
      queryParamString={queryParamString}
    />
  );
};

DynamicGridBreaker.propTypes = {
  blok: PropTypes.shape({
    row: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  }),
  queryParamString: PropTypes.string,
};

export default DynamicGridBreaker;
